import React from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";
import MainLayout from "Layout/layout";
import Footer from "Layout/Footer/Footer";
import PageContent from "Layout/PageContent/PageContent";
import Hero from "Layout/Hero/Hero";
import config from "data/SiteConfig";

const Imprint = (props) => {
  const { location, pageContext, data } = props;
  const {  page } = data

  return (
    <MainLayout location={location} pageContext={pageContext}>
      <Helmet
        meta={[
          {
            property: 'og:title',
            content: page.acf.meta_info_title,
          },
          {
            property: 'og:description',
            content: page.acf.meta_info_description,
          },
          {
            property: 'og:image',
            content: page.acf.social_share_image.image.localFile.childImageSharp.fluid.src,
          },
          {
            property: 'og:url',
            content: location.href,
          },
          {
            property: 'og:site_name',
            content: config.siteTitle,
          },
          {
            name: 'twitter:image:alt',
            content: page.acf.meta_info_description,
          }
        ]}
      />
      <Hero
        headline={page.title}
      />
      <PageContent text={page.content} />
      <Footer />
    </MainLayout>
  );
}

export const query = graphql`
  query ImprintPageQuery($id: String!) {
    page: wordpressPage(id: { eq: $id }) {
      title
      slug
      content
      acf {
        social_share_image {
          image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1200) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default Imprint

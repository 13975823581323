import React from "react";
import classNames from "classnames";
import "./PageContent.scss";

const PageContent = (props) => {
  const { text } = props;

  return (
    <section className="container-fluid section intro-text-section py-5">
      <div className="container">
        {
          text && (
            <div className="row justify-content-center">
              <div
                dangerouslySetInnerHTML={{ __html: text }}
                className={
                  classNames("col-12 col-md-8 col-lg-6 text-align-center text-m")
                }
              />
            </div>
          )
        }
      </div>
    </section>
  );
}

export default PageContent